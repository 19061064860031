import { project } from './initializeStats';
import { statsArticle } from './statsArticle';
import { inViewport } from './elementsInViewport';
import { linkStats, setStatElementCollector, shareStats } from './stats';
import { wideo } from './wideo';
import { menu } from './menu';
import { anchors } from './anchors';
import { debounce } from './helpers';
import { accordion, resizeAccordion } from './widgets/accordeon';
import { galleryHover } from './widgets/galleryHover';
import { sliderSwiperWindow, sliderSwiperWide } from './widgets/slider';
import { audioPlay } from './widgets/audio';
import { galleryHotpoints } from './widgets/hotpoints';
import { shareButtonsAction } from './share';
import {carSlider} from "./widgets/car-slider";




anchors();
statsArticle();
setStatElementCollector();
linkStats();
inViewport();
shareStats();
wideo();
menu();
accordion();
galleryHover();
galleryHotpoints();
sliderSwiperWindow();
sliderSwiperWide();
audioPlay();
shareButtonsAction();
carSlider();


const changeSizesOnResize = () => {
  resizeAccordion();
};

window.addEventListener('resize', debounce(changeSizesOnResize, 500));

window.addEventListener('beforeunload', project.unloadData.bind(project));

// for testing
// document.querySelector('.header').addEventListener('click', project.unloadData.bind(project));



