import { statsOnWidgetInteraction } from '../stats';

export function galleryHover() {
  if (document.querySelectorAll('.gallery-hover').length) {
    const itemHover = function (item) {
      item.classList.add('item--active');
      setTimeout(() => {
        item.querySelector('.gallery-hover__pin').classList.add('tapped');
      }, 500);
    };
    const eventName = 'ontouchstart' in document.documentElement ? 'click' : 'mouseenter';

    document.querySelectorAll('.gallery-hover').forEach((gallery) => {
      gallery.querySelectorAll('.widget__item').forEach((el) => {
        el.addEventListener(eventName, function () {
          itemHover(this);
          statsOnWidgetInteraction(
            this,
            this.querySelector('.gallery-hover__img').getAttribute('src'),
          );
        });
      });

      gallery.querySelectorAll('.widget__item').forEach((el) => {
        el.addEventListener('mouseleave', function () {
          this.classList.remove('item--active');
          this.querySelector('.gallery-hover__pin').classList.remove('tapped');
        });
      });

      gallery.querySelectorAll('.gallery-hover__pin').forEach((el) => {
        el.addEventListener('click', (ev) => {
          ev.stopPropagation();
          if (el.classList.contains('tapped')) {
            el.closest('.widget__item').classList.remove('item--active');
            el.classList.remove('tapped');
          } else {
            itemHover(el.closest('.widget__item'));
          }
        });
      });
    });
  }
}
