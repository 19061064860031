import { sendStats } from './stats';

export const wideo = () => {
  document.querySelectorAll('.player').forEach((el, i) => {
    el.setAttribute('id', `player_${i}`);
    const url = el.getAttribute('data-url');

    WP.push(() => {
      let playerInitialized = false;
      const statsQuadrantsPos = {};
      let clipName;
      let clipMid;
      const player = new WP.player({
        url,
        target: `player_${i}`,
        adv: false,
        hiderelated: true,
        extendedrelated: false,
        floatingplayer: false,
        autoplay: false,
        forceliteembed: false,
      });
      const statsQuadrants = (p) => {
        p = Math.round(p * 100);
        if (!statsQuadrantsPos[clipMid]) {
          statsQuadrantsPos[clipMid] = {};
        }
        if (p >= 25 && p < 50) {
          if (!statsQuadrantsPos[clipMid][25]) {
            statsQuadrantsPos[clipMid][25] = 1;
            sendStats('video', 'quarter=25', clipName);
            // console.log("video", "quarter=25", clipName);
          }
        } else if (p >= 50 && p < 75) {
          if (!statsQuadrantsPos[clipMid][50]) {
            statsQuadrantsPos[clipMid][50] = 1;
            sendStats('video', 'quarter=50', clipName);
            // console.log("video", "quarter=50", clipName);
          }
        } else if (p >= 75 && p < 100) {
          if (!statsQuadrantsPos[clipMid][75]) {
            statsQuadrantsPos[clipMid][75] = 1;
            sendStats('video', 'quarter=75', clipName);
            // console.log("video", "quarter=75", clipName);
          }
        }
      };
      player.on('INIT', () => {
        if (!playerInitialized) {
          playerInitialized = true;
          player.setVolume(0.5);
        }
      });
      player.on('START_MOVIE', () => {
        clipName = player.getClipData().title;
        clipMid = player.getClipData().mid;
        sendStats('video', 'view', clipName);
        // console.log("video", "view", clipName);

          const myImage = new Image(0,0);
          myImage.src = "https://adservice.google.com/ddm/fls/z/src=8241951;dc_pre=CP3GqL7wk4IDFebIOwIdtAQBTg;type=cwiartki;cat=cw_100;u60=4446534;u61=30895401;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ord=1?";          
          console.log('video_start');
      });
      player.on('END_MOVIE', () => {
        sendStats('video', 'end', clipName);
        // console.log("video", "end", clipName);
      });
      player.on('UPDATE_POSITION', (e) => {
        const position = player.getPosition();
        statsQuadrants(position);
      });
    });
  });
};
