import {statsOnWidgetInteraction} from "../stats";

export const carSlider = () => {
    document.querySelectorAll('.article_swiper_boxes').forEach((parent) => {
        const gallerySlider = parent.querySelector('.gallery-slider');
        const btnPrev = parent.querySelector('.swiper-button-prev');
        const btnNext = parent.querySelector('.swiper-button-next');

        const slidesLength = gallerySlider.querySelectorAll('.swiper-slide').length;
        let realIdx = 0;

        const swiper = new Swiper(gallerySlider, {
            loop: true,
            grabCursor: true,
            resizeObserver: true,
            updateOnWindowResize: true,
            spaceBetween: 20,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    centeredSlides: true,
                },
                520: {
                  slidesPerView: 2,
                    centeredSlides: false,
                },
                640: {
                    slidesPerView: 3,
                    centeredSlides: true,
                },
            },
            navigation: {
                nextEl: btnNext,
                prevEl: slidesLength !== 5 ? btnPrev : null,
            },
            on: {
                realIndexChange() {
                    const eventAction = 'ontouchstart' in document.documentElement ? 'tap' : 'click';
                    const evType = this.swipeDirection ? 'swipe' : eventAction;
                    const item = this.el.querySelector(`[data-swiper-slide-index="${this.realIndex}"]`);
                    statsOnWidgetInteraction(
                        item,
                        item.querySelector('img').getAttribute('src'),
                        this.realIndex,
                        evType
                    );
                    realIdx = this.realIndex;
                },
            },
        });

        if (slidesLength === 5) {
            btnPrev.addEventListener('click', () => {
                if (realIdx === slidesLength - 1) {
                    swiper.slideToLoop(realIdx - 1, 200, false);
                } else {
                    swiper.slideToLoop(realIdx - 1, 400, false);
                }
            });
        }

        gallerySlider.classList.add('swiper-initialized');
    });
}