import { statsOnWidgetInteraction } from '../stats';
import { project } from '../initializeStats';

export const resizeAccordion = () => {
  document.querySelectorAll('.gallery-accordion').forEach((el) => {
    el.querySelectorAll('.widget__item').forEach((item) => {
      const width = window.innerWidth > 768
        ? `${el.querySelector('.accordion__element--hover').getBoundingClientRect().width - el.querySelector('.accordion__element .accordion__title').getBoundingClientRect().width}px`
        : '100%';
      item.querySelector('.accordion__accordion-desc').style.width = width;
      item.querySelector('.accordion__img img').style.width = width;
    });
  });
};

export function accordion() {
  if (document.querySelectorAll('.gallery-accordion').length > 0) {
    resizeAccordion();
    document.querySelectorAll('.gallery-accordion').forEach((slider) => {
      slider.querySelectorAll('.widget__item').forEach((el) => {
        el.addEventListener('mousemove', () => {
          if (!project.isScrollingFlag && !el.classList.contains('accordion__element--hover')) {
            slider.querySelectorAll('.widget__item').forEach((acE) => {
              acE.classList.remove('accordion__element--hover');
            });
            el.classList.add('accordion__element--hover');
            statsOnWidgetInteraction(el, el.querySelector('.accordion__title').innerText);
          }
        });
      });
    });
  }
}
