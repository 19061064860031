import { statsOnWidgetInteraction } from '../stats';

export function sliderSwiperWindow() {
  document.querySelectorAll('.article_swiper_window').forEach((parent) => {
    const gallerySlider = parent.querySelector('.gallery-slider');
    const btnPrev = parent.querySelector('.swiper-button-prev');
    const btnNext = parent.querySelector('.swiper-button-next');

    const slidesLength = gallerySlider.querySelectorAll('.swiper-slide').length;
    let realIdx = 0;

    const swiper = new Swiper(gallerySlider, {
      slidesPerView: 1,
      effect: "fade",
      centeredSlides: true,
      pagination: {
        el: parent.querySelector('.swiper-pagination'),
        clickable: true,
      },
      loop: true,
      spaceBetween: 0,
      freeMode: true,
      navigation: {
        nextEl: btnPrev,
        prevEl: btnNext,
      },
      on: {
        realIndexChange() {
          const eventAction = 'ontouchstart' in document.documentElement ? 'tap' : 'click';
          const evType = this.swipeDirection ? 'swipe' : eventAction;
          const item = this.el.querySelector(`[data-swiper-slide-index="${this.realIndex}"]`);
          statsOnWidgetInteraction(
            item,
            item.querySelector('img').getAttribute('src'),
            this.realIndex,
            evType
          );
          realIdx = this.realIndex;
        },
      },
    });

    gallerySlider.classList.add('swiper-initialized');
  });
}

export function sliderSwiperWide() {
  document.querySelectorAll('.article_swiper_wide').forEach((parent) => {
    const gallerySlider = parent.querySelector('.gallery-slider');
    const btnPrev = parent.querySelector('.swiper-button-prev');
    const btnNext = parent.querySelector('.swiper-button-next');

    const slidesLength = gallerySlider.querySelectorAll('.swiper-slide').length;
    let realIdx = 0;

    const swiper = new Swiper(gallerySlider, {
      loop: true,
      grabCursor: true,
      resizeObserver: true,
      updateOnWindowResize: true,
      centeredSlides: true,
      breakpoints: {
        // when window width is >= 0px (mobile)
        // 0: {
        //   slidesPerView: 2,
        // },
        // when window width is >= 769px (tablet)
        769: {
          slidesPerView: 3,
        },
        // when window width is >= 1024px (desktop)
        1024: {
          slidesPerView: 5,
          // spaceBetween: 10,
        },
      },
      navigation: {
        nextEl: btnNext,
        prevEl: slidesLength !== 5 ? btnPrev : null,
      },
      pagination: {
        el: parent.querySelector('.swiper-pagination'),
        clickable: true,
      },
      on: {
        realIndexChange() {
          const eventAction = 'ontouchstart' in document.documentElement ? 'tap' : 'click';
          const evType = this.swipeDirection ? 'swipe' : eventAction;
          const item = this.el.querySelector(`[data-swiper-slide-index="${this.realIndex}"]`);
          statsOnWidgetInteraction(
            item,
            item.querySelector('img').getAttribute('src'),
            this.realIndex,
            evType
          );
          realIdx = this.realIndex;
        },
      },
    });

    if (slidesLength === 5) {
      btnPrev.addEventListener('click', () => {
        if (realIdx === slidesLength - 1) {
          swiper.slideToLoop(realIdx - 1, 200, false);
        } else {
          swiper.slideToLoop(realIdx - 1, 400, false);
        }
      });
    }

    gallerySlider.classList.add('swiper-initialized');
  });
}



// export function sliderSwiperWide() {
// 	if (document.querySelectorAll('.swiper__container.__ver_wide').length) {
// 		document.querySelectorAll('.swiper__container.__ver_wide').forEach((gallery) => {
// 			const gallerySlider = gallery.querySelector('.gallery-slider');
// 			const btnPrev = gallery.querySelector('.swiper-button-prev');
// 			const btnNext = gallery.querySelector('.swiper-button-next');
// 			const slidesLength = gallerySlider.querySelectorAll('.swiper-slide').length;
// 			let realIdx = 0;
// 			const swiper = new Swiper(gallerySlider, {
// 				loop: true,
// 				grabCursor: true,
// 				resizeObserver: true,
// 				updateOnWindowResize: true,
// 				centeredSlides: true,


// 				slidesPerView: 3.5,				

			
// 				navigation: {
// 					nextEl: btnNext,
// 					prevEl: slidesLength !== 5 ? btnPrev : null,
// 				  },
// 				  on: {
// 					realIndexChange() {
// 					  const eventAction =
// 						'ontouchstart' in document.documentElement ? 'tap' : 'click';
// 					  const evType = this.swipeDirection ? 'swipe' : eventAction;
// 					  const item = this.el.querySelector(
// 						`[data-swiper-slide-index="${this.realIndex}"]`
// 					  );
// 					  statsOnWidgetInteraction(
// 						item,
// 						item.querySelector('img').getAttribute('src'),
// 						this.realIndex,
// 						evType
// 					  );
// 					  realIdx = this.realIndex;
// 					},
// 				  },
// 				pagination: {
// 					el: ".swiper-pagination",
// 				}				
// 			});

// 			if (slidesLength === 5) {
// 				btnPrev.addEventListener('click', () => {
// 				  if (realIdx === slidesLength - 1) {
// 					swiper.slideToLoop(realIdx - 1, 200, false);
// 				  } else {
// 					swiper.slideToLoop(realIdx - 1, 400, false);
// 				  }
// 				});
// 			  }

// 		});
// 	}
// }
