import { formatTime } from '../helpers';

export const audioPlay = () => {
  if (document.querySelectorAll('.audio--play').length > 0 && document.querySelectorAll('.btn--play').length > 0) {
    document.querySelectorAll('.btn--play').forEach((btn) => {
      const audio = btn.closest('.audio__wrapper').querySelector('.audio--play');
      const btnText = btn.querySelector('.btn--play__text');
      const btnTime = btn.querySelector('.btn--play__time');
      const setPlay = () => {
        if (audio.paused) {
          audio.play();
          btnTime.classList.remove('__hidden');
        } else {
          audio.pause();
          btnTime.classList.add('__hidden');
        }
        btnText.innerText = !audio.paused ? 'zatrzymaj' : 'wznów';
        audio.addEventListener('timeupdate', () => {
          btnTime.innerText = formatTime(Math.ceil(audio.duration - audio.currentTime));
        });
        audio.addEventListener('ended', () => {
          btnText.innerText = 'odtwórz ponownie';
          btnTime.classList.add('__hidden');
        });
      };
      // const handleMouseMove = () => {
      //   setPlay();
      //   document.querySelector('.section-hero').removeEventListener('mouseover', handleMouseMove);
      // };
      // document.querySelector('.section-hero').addEventListener('mouseover', handleMouseMove);
      btn.addEventListener('click', () => {
        setPlay();
      });
    });
  }
};
