import { sendStats } from './stats';
import { project } from './initializeStats';
import { getIndex, scrollToEvent } from './helpers';

export const anchors = function () {
  const initSlug = document.location.pathname.split('/').pop()
      || document.querySelectorAll('.__js_stats_wrapper')[0].getAttribute('id');

  project.activeSlug = initSlug;
  const customOffset = document.querySelector('.header').getBoundingClientRect().height;
  // const customOffset = parseInt(getComputedStyle(document.documentElement)
  //   .getPropertyValue('--header-height'));
  const sections = document.querySelectorAll('.__js_stats_wrapper');
  let wp_content_selector;
  const animationTime = 800;
  let sectionScrollCheckTS = null;
  let reportSlugTS = -1;

  const reportSlug = function () {
    if (!statData[project.activeSlug]) {
     console.log('Błędny slug w url');
      return;
    }
    if (project.lastReportedSlug === null) {
      // wyślij statystykę pageview standard
      // console.log('statData[project.activeSlug]', statData);

      wp_dot_addparams.ctags = statData[project.activeSlug].tags;
      WP.push(() => {
        WP.pwp.viewPage({
          ga: {
            event: 'pageview',
            page: {
              pageviewType: 'standard',
              error404: basicStatData.ga.page.error404,
              templateType: 'AS',
            },
            site: {
              name: basicStatData.ga.site.name,
              version: basicStatData.ga.site.version,
              cms: basicStatData.ga.site.cms,
              as: {
                orderId: asorderid,
                productName: asproduct,
                name: asname,
              },
            },
            content: {
              title: statData[project.activeSlug].title,
              category: {
                IAB: statData[project.activeSlug].category.IAB,
                Gemius: statData[project.activeSlug].category.Gemius,
              },
              tags: statData[project.activeSlug].tags,
            },
          },
        });
      });
    } else {
      // wyślij statystykę pageview scrolboost vpv. pageviewType ma wartość "overlay"
      // gdy nie mamy doładowania i "scrollboost" gdy następuje doładowanie, ALE dla pseudo dynamicznych stron AS ma byc jednak scrollboost ;) - ustalone z Salestube !
      wp_content_selector = document.getElementById(project.lastReportedSlug);

      project.unloadData(project.lastReportedSlug);

      wp_dot_addparams.ctags = statData[project.activeSlug].tags;
      WP.push(() => {
        WP.pwp.viewPage({
          ga: {
            event: 'pageviewVpv',
            page: {
              pageviewType: 'scrollboost',
              error404: basicStatData.ga.page.error404,
              templateType: 'AS',
            },
            site: {
              name: basicStatData.ga.site.name,
              version: basicStatData.ga.site.version,
              cms: basicStatData.ga.site.cms,
              as: {
                orderId: asorderid,
                productName: asproduct,
                name: asname,
              },
              frontendPlatform: 'other',
            },
            content: {
              title: statData[project.activeSlug].title,
              category: {
                IAB: statData[project.activeSlug].category.IAB,
                Gemius: statData[project.activeSlug].category.Gemius,
              },
              tags: statData[project.activeSlug].tags,
            },
            // "content": statData[project.activeSlug]
          },
        });
      });
    }

    project.lastReportedSlug = project.activeSlug;
    reportSlugTS = -1;
  };

  const changeActiveSlug = function (slug) {
    project.activeSlug = slug;

    if (document.querySelector('.header--nav [data-slug]')) {
      document.querySelectorAll('.header--nav li a').forEach((el) => el.classList.remove('__active'));
      document.querySelector(`.header--nav [data-slug="${slug}"]`).classList.add('__active');
      // document.querySelector('.header--nav ul').setAttribute('class', `order_${getIndex(document.querySelector(`.header--nav [data-slug="${slug}"]`).closest('li'))}`);
    }
    const dSN = slug === 'intro' ? defaultSiteTitle : `- ${defaultSiteTitle}`;
    if (document.location.search.length > 0) {
      history.pushState(
        {
          id: slug,
        },
        slug,
        slug + document.location.search,
      );
      if (document.querySelector('title')) {
        document.querySelector('title').innerText = `${statData[project.activeSlug].title} ${dSN}`;
      }
      if (document.querySelector('meta[property="og:title"]')) {
        document.querySelector('meta[property="og:title"]').content = `${statData[project.activeSlug].title} ${dSN}`;
      }
      if (document.querySelector('meta[name="twitter:title"]')) {
        document.querySelector('meta[name="twitter:title"]').content = `${statData[project.activeSlug].title} ${dSN}`;
      }
    } else {
      history.pushState(
        {
          id: slug,
        },
        slug,
        slug,
      );
      if (document.querySelector('title') && statData[project.activeSlug]) {

        document.querySelector('title').innerText = `${statData[project.activeSlug].title} ${dSN}`;
      }
      if (document.querySelector('meta[property="og:title"]')) {
        document.querySelector('meta[property="og:title"]').content = `${statData[project.activeSlug].title} ${dSN}`;
      }
      if (document.querySelector('meta[name="twitter:title"]')) {
        document.querySelector('meta[name="twitter:title"]').content = `${statData[project.activeSlug].title} ${dSN}`;
      }
    }

    if (project.lastReportedSlug === null) {
      // jeżeli pierwsze wejście to wysyłaj pageview od razu
      reportSlug();
    } else if (project.lastReportedSlug !== slug) {
      // jeżeli zmiana sluga to wysyłaj po sekundzie statystykę

      if (reportSlugTS > -1) clearTimeout(reportSlugTS);
      reportSlugTS = setTimeout(reportSlug, 1000);
    } else if (reportSlugTS > -1) clearTimeout(reportSlugTS);
  };

  /* Sprawdzanie czy user zescrollował do sekcji. Jeżeli tak to ustawia pozycję w nawigacji */
  const sectionScrollCheck = function () {
    sectionScrollCheckTS = setTimeout(sectionScrollCheck, 200);

    const topScroll = document.querySelector('body').getBoundingClientRect().top === 0 ? 0 : -(document.querySelector('body').getBoundingClientRect().top);

    // wymuszenie strony głównej
    if (
      topScroll <= customOffset
        && document.location.pathname.split('/').pop().length < 1
    ) {
      changeActiveSlug(sections[0].getAttribute('id'));
      return;
    }

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      // const sectionOffset = section.getBoundingClientRect().top;
      const sectionOffset = section.offsetTop;
      const sectionHeight = section.getBoundingClientRect().height;
      // console.info(i, topScroll, sectionOffset, sectionOffset + sectionHeight);
      if (
        project.activeSlug !== section.getAttribute('id')
          && topScroll > sectionOffset - customOffset - 10
          && topScroll < sectionOffset + sectionHeight - customOffset - 10
      ) {
        // console.info(i, topScroll, sectionOffset, sectionOffset + sectionHeight);
        changeActiveSlug(section.getAttribute('id'));
        break;
      }
    }
  };

  /* Inicjalizacja metod dla obsługi nawigacji - clików i sprawdzanie scrolla */
  const initNavigation = function () {
    sectionScrollCheckTS = setTimeout(sectionScrollCheck, animationTime + 25);

    document.querySelectorAll('[data-slug]').forEach((el) => {
      el.addEventListener(
        'click',
        (e) => {
          // console.log('click');
          if (sectionScrollCheckTS) clearTimeout(sectionScrollCheckTS);

          e.preventDefault();
          let slug = el.getAttribute('data-slug');
          if (!slug.length) slug = '/';

          changeActiveSlug(slug);
          project.isScrollingController(true);
          scrollToEvent(document.getElementById(slug).getBoundingClientRect().top + window.scrollY - customOffset, () => {
            project.isScrollingController(false);
          });
          // window.scrollTo({ top: document.getElementById(slug).getBoundingClientRect().top + window.scrollY - customOffset, behavior: 'smooth' });
          // document.getElementById(slug).scrollIntoView({
          //   behavior: 'smooth',
          // });
          sectionScrollCheckTS = setTimeout(
            sectionScrollCheck,
            animationTime + 25,
          );
        },
      );
    });
  };

  const scrollOnStart = function () {
    if (document.getElementById(initSlug)) {
      // document.getElementById(initSlug).scrollIntoView({
      //   behavior: 'smooth',
      // });
      project.isScrollingController(true);
      scrollToEvent(document.getElementById(initSlug).getBoundingClientRect().top + window.scrollY - customOffset, () => {
        project.isScrollingController(false);
      });
      // console.log('scroll');
    }

    sendStats('progress', 'start', initSlug);

    changeActiveSlug(initSlug);
    initNavigation();
  };
  // staty pageviews i scrollboost pwp ----- {

  if (initSlug.length > 1) {
    setTimeout(scrollOnStart, 500);
  }
};
