import { project } from './initializeStats';
import { getIndex } from './helpers';

export function sendStats(category, action, label, value) {
  if (value) {
    try {
      WP.stat.show(
        wp_sn,
        {
          category,
          label,
          value,
          asproduct,
          asorderid,
          asname,
        },
        action,
      );
    } catch (e) {
      console.log(`stat.show ERROR: ${e}`);
    }
  } else {
    try {
      WP.stat.show(
        wp_sn,
        {
          category,
          label,
          asproduct,
          asorderid,
          asname,
        },
        action,
      );
    } catch (e) {
      console.log(`stat.show ERROR: ${e}`);
    }
  }
}

export function linkStats() {
  document.querySelectorAll('[data-ga360-click]').forEach((el) => {
    const clickEvent = function (ev) {
      el.addEventListener(ev, function () {
        const eventCategory = this.getAttribute('data-ga360-click');
        const eventAction = 'ontouchstart' in document.documentElement ? 'tap' : 'click';
        let eventLabel;
        if (this.closest('.header')) {
          eventLabel = 'header';
        } else if (this.closest('.footer')) {
          eventLabel = 'footer';
        } else {
          eventLabel = window.location.href;
        }
        WP.push(() => {
          WP.pwp.customAction({
            ga: {
              event: 'site_interaction',
              eventCategory,
              eventAction,
              eventLabel,
            },
          });
        });

        sendStats(eventCategory, eventAction, eventLabel);
      });
    };
    clickEvent('click');
    clickEvent('auxclick');
    clickEvent('contextmenu');
  });
}

export function shareStats() {
  document.querySelectorAll('.__js_socialShare').forEach((el) => {
    el.addEventListener('click', function (e) {
      e.preventDefault();
      const eventCategory = this.getAttribute('data-id');
      const href = this.getAttribute('data-href');
      const eventAction = 'ontouchstart' in document.documentElement ? 'tap' : 'click';
      let ga360Socials;
      const eventLabel = window.location.href;
      if (eventCategory === 'facebook') {
        window.open(
          href,
          '_blank',
          'top=100, left=300, width=320,height=320,toolbar=1,resizable=0',
        );
        ga360Socials = 'share_FB';
      }
      if (eventCategory === 'instagram') {
        window.open(
          href,
          '_blank',
          'top=100, left=300, width=320,height=320,toolbar=1,resizable=0',
        );
        ga360Socials = 'share_Instagram';
      }
      if (eventCategory === 'twitter') {
        window.open(
          href,
          '_blank',
          'top=100, left=300, width=320,height=320,toolbar=1,resizable=0',
        );
        ga360Socials = 'share_Twitter';
      }
      if (eventCategory === 'linkedin') {
        window.open(
          href,
          '_blank',
          'top=100, left=300, width=320,height=320,toolbar=1,resizable=0',
        );
        ga360Socials = 'share_Linkedin';
      }

      WP.push(() => {
        WP.pwp.customAction({
          ga: {
            event: 'site_interaction',
            eventCategory: ga360Socials,
            eventAction,
            eventLabel,
          },
        });
      });

      sendStats(eventCategory, eventAction, eventLabel);
    });
  });
}

export function setStatElementCollector() {
  document.querySelectorAll('[data-ga360-id]').forEach(function (el) {
    const artId = el.closest('.__js_stats_wrapper').getAttribute('data-id');
    const ga360id = el.getAttribute('data-ga360-id');
    if (ga360id === undefined) {
      console.log(
        `%c dodać ga360-id do Galerii hover: ${this.getAttribute('data-id')}`,
        'background: #553311; padding: 3px; color: #ffaaaa',
      );
    } else {
      project.statElementCollector[`${ga360id}-${artId}`] = {
        id: ga360id,
        moduleName: el.getAttribute('data-id'),
        viewedCount: 0,
        itemCount: el.querySelectorAll('.widget__item').length,
      };
    }
    el.statVievedSlide = [];
    project.slidePositionVieved[`${ga360id}-${artId}`] = {};
  });
}

export function statsOnWidgetInteraction(item, arg, idx, evType) {
  const artId = item.closest('.__js_stats_wrapper').getAttribute('data-id');
  const ga360id = item.closest('[data-ga360-id]').getAttribute('data-ga360-id');
  const dataId = item.closest('[data-id]').getAttribute('data-id');
  const isClick = !!item.closest('.gallery-slider');
  const hoverEventType = 'ontouchstart' in document.documentElement ? 'tap' : 'hover';

  const itemId = arg;
  if (!ga360id) {
    console.log(
      `%c dodać ga360-id do ${dataId}background: #3b3b3b; padding: 3px; color: #beeb2f`,
    );
  }
  const index = idx || getIndex(item);

  if (!project.isScrollingFlag) {
    project.slidePositionVieved[`${ga360id}-${artId}`][index] = 1;
    project.statElementCollector[`${ga360id}-${artId}`].viewedCount = Object.keys(project.slidePositionVieved[`${ga360id}-${artId}`]).length;
    sendStats(
      'gallery',
      `${isClick && evType ? evType : hoverEventType}-${index}`,
      `${artId} / ${dataId}`,
      itemId,
    );
    WP.pwp.customAction({
      ga: {
        event: 'content_interaction',
        eventCategory: ga360id,
        moduleName: dataId,
        eventAction: isClick && evType ? evType : hoverEventType,
      },
    });
  }
}
