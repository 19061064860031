export function shareButtonsAction() {
	
		document.querySelectorAll('.social__btn').forEach(function (el) {
			el.insertAdjacentHTML(
				'afterbegin',
				'<span class="share-container">\n' +
				'          <span class="share-top"></span>\n' +
				'          <span class="share-mid"></span>\n' +
				'          <span class="share-bottom"></span>\n' +
				'        </span>'
			);
		});


        let eventName;
        const isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
        if (!isTouch) {
            eventName = 'click';
        } else {
            eventName = 'touchstart';
        };

        document.querySelectorAll('.social__btn').forEach((sbmTrigger) => {
            
            sbmTrigger.addEventListener(eventName, function () {
                if(this.parentNode.classList.contains('socials--expanded')) {
                    this.parentNode.classList.remove('socials--expanded');    
                } else this.parentNode.classList.add('socials--expanded');
            })
            
        });
    }