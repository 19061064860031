
import { statsOnWidgetInteraction } from '../stats';

export function galleryHotpoints() {
  if (document.querySelectorAll('.hotpoints').length) {
    
  
    function getDomIndex (target) {
        return [].slice.call(target.parentNode.children).indexOf(target)
    }

    const itemClick = function (item) {
		item.classList.add('hover');
		const index = getDomIndex(item) + 1;
		const mnode = document.querySelector(`.hotpoints--desc-mobile .hotpoints--poi-txt:nth-of-type(${index})`);
		mnode.classList.add('hover');
		item.querySelector('.hotpoints--poi-txt').classList.add('hover');
    };
    
    let eventName;
	const isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
	if (!isTouch) {
		eventName = 'click';
	} else {
		eventName = 'touchstart';
	};
	

    document.querySelectorAll('.hotpoints').forEach((gallery) => {

        gallery.querySelectorAll('.hotpoints--poi').forEach((el) => {
            el.addEventListener(eventName, function () {
            	itemClick(this);
            	const id = this.parentNode.dataset.id;
				if (!!id) {
					const i = getDomIndex(this) + 1;
					console.log(i, 'asd: ' +  i + 'id: ' + id);
					statsOnWidgetInteraction(this, id, i);
				} else {
					console.warn('nadaj id dla: ' + this.parentNode);					
				}
            });
        });

        gallery.querySelectorAll('.hotpoints--poi').forEach((el) => {
            el.addEventListener('mouseleave', function () {
                this.classList.remove('hover');
                this.querySelector('.hotpoints--poi-txt').classList.remove('hover');
            });
        });


      gallery.querySelectorAll('.hotpoints--close').forEach((el) => {
        el.addEventListener(eventName, (ev) => {
          ev.stopPropagation();
          if (el.parentNode.classList.contains('hover')) {
			el.parentNode.classList.remove('hover');            
            el.closest('.hotpoints--poi').classList.remove('hover');
          } 
        });
      });


    });
  }
}