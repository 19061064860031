export const menu = () => {
  if (document.querySelector('.header--hamburger')) {
    const hamburger = document.querySelector('.header--hamburger');
    const nav = document.querySelector('.header--nav');
    const hamburgerWrapper = document.querySelector('.hamburger-wrapper');
    for (let i = 0; i < 3; i++) {
      const span = document.createElement('span');
      hamburger.appendChild(span);
    }
    hamburgerWrapper.addEventListener('click', () => {
      hamburger.classList.toggle('__js_isActive');
      nav.classList.toggle('__js_isActive');
      hamburgerWrapper.classList.toggle('__js_isActive');
    });
    nav.querySelector('ul').addEventListener('click', () => {
      nav.classList.remove('__js_isActive');
      hamburger.classList.remove('__js_isActive');
      hamburgerWrapper.classList.remove('__js_isActive');
    });
    document.querySelector('.header').addEventListener('mouseleave', () => {
      nav.classList.remove('__js_isActive');
      hamburger.classList.remove('__js_isActive');
      hamburgerWrapper.classList.remove('__js_isActive');
    });
  }
};
